@import "~@/assets/scss/global.scss";































































































































































































::v-deep {
    .el-tag {
        height: 30px;
        margin-left: 5px !important;
        margin-bottom: 5px !important;

        .el-input__inner {
            height: 22px;
            line-height: 22px;
            padding: 0 5px;
            text-align: center;
            margin-left: 5px;
        }
    }

    .stock-title {
        width: calc(100% - 40px);

        &>div {
            display: inline-block;
        }
    }
}
